const ImprintContent = () => {
    return (
        <div className='imprint__content-text' >
            <h3>Impressum</h3>
            <p>
                Kanzlei Gören<br />
                Davut Gören, Rechtsanwalt<br />
                Engelsbyer Straße 30<br />
                24943 Flensburg
            </p>
            <p>
                Kontakt:<br />
                Telefon: <a href='tel:0461 40681173'>046140681173</a><br />
                E-Mail: <a href='mailto:info@kanzleigoeren.de'>info@kanzleigoeren.de</a><br />
                Website: <a href='https://www.kanzleigoeren.de'>www.kanzleigoeren.de</a>
            </p>

            <p>Rechtsanwalt Davut Gören ist Mitglied in der Schleswig-Holsteinischen Rechtsanwaltskammer:</p>
            <p>
                Schleswig-Holsteinische Rechtsanwaltskammer<br />
                Gottorfstraße 13<br />
                24837 Schleswig<br />
                T 04621/93 91-0<br />
                F 04621/93 91-26<br />
                info@rak-sh.de<br />
                www.rak-sh.de<br />
            </p>
            <p>
                Rechtsgrundlage für die Tätigkeit der Rechtsanwaltskammer ist die Bundesrechtsanwaltsordnung (BRAO). Den Text
                der BRAO finden Sie u.a. auf der Internetseite der Bundesrechtsanwaltskammer unter www.brak.de.
            </p>

            <p>Für Rechtsanwälte gelten folgende berufsrechtliche Regelungen:</p>
            <ul>
                <li>Bundesrechtsanwaltsordnung (BRAO)</li>
                <li>Berufsordnung der Rechtsanwälte (BORA)</li>
                <li>Gesetz über die Vergütung der Rechtsanwältinnen und Rechtsanwälte (RVG)</li>
                <li>Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft (CCBE)</li>
            </ul>

            <p>
                Rechtsanwältinnen und Rechtsanwälte sind aufgrund der Bundesrechtsanwaltsordnung verpflichtet, eine
                Berufshaftpflichtversicherung mit einer Mindestversicherungssumme von 250.000,00 € zu unterhalten (§ 51
                BRAO).
            </p>

            Berufshaftpflichtversicherung Davut Gören:<br />
            mailo Versicherung AG<br />
            Riehler Straße 1<br />
            50668 Köln<br />
            <br />

            <h3>Datenschutzerklärung</h3>
            <h4>§ 1 Informationen über die Erhebung personenbezogener Daten</h4>
            <p>1. Im Folgenden wird über die Erhebung personenbezogener Daten bei Nutzung dieser Website informiert.
                Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.B Name, Adresse,
                E-Mail-Adressen, Nutzerverhalten.</p>
            <p>2. Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist:<br />Davut Gören<br />
                Engelsbyer Straße 30,<br />24943 Flensburg<br />E-Mail: info@kanzleigoeren.de<br />Telefon: 046140681173</p>
            <p>3. Falls wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6 Abs.
                1 S. 1 lit. a) DS-GVO Rechtsgrundlage.</p>
            <p>4. Ist die Verarbeitung Ihrer Daten zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO
                Rechtsgrundlage.</p>
            <p>5. Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen (z.B.
                gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.</p>
            <p>6. Bei der Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten
                Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu
                beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
                erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
            </p>
            <p>7. Falls wir für einzelne Funktionen unseres Angebotes auf beauftragte Dienstleister zurückgreifen oder Ihre
                Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen
                Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
            </p><h4>§ 2 Ihre Rechte</h4>
            <p>1. Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>
            <ul>
                <li>Recht auf Auskunft,</li>
                <li>Recht auf Berichtigung oder Löschung,</li>
                <li>Recht auf Einschränkung der Verarbeitung,</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>Recht auf Datenübertragbarkeit.</li>
            </ul>
            <p>2. Sie haben zudem das Recht , sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten durch uns zu beschweren.</p>
            <h4>§ 3 Erhebung personenbezogener Daten bei Besuch dieser Website</h4>
            <p>Bei der bloß informatorischen Nutzung dieser Webseite, also wenn Sie sich nicht registrieren oder uns
                anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an
                unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die
                für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu
                gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):</p>
            <ul>
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils übertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfläche</li>
                <li>Sprache und Version der Browsersoftware</li>
            </ul>
        </div>
    );
}

export default ImprintContent;