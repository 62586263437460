import './navigation.css';

function Navigation() {
    return (
        <nav>
            <ul>
                <li><a href='#home'>Home</a></li>
                <li><a href='#references'>Referenzen</a></li>
                <li><a href='#law'>Rechtsgebiete</a></li>
                <li><a href='#company'>Kanzlei</a></li>
                <li><a href='#contact'>Kontakt</a></li>
                <li><a href='#downloads'>Downloads</a></li>
            </ul>
        </nav>
    );
  }
  
  export default Navigation;