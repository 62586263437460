const References = () => {
    return (
        <section id='references'>
            <div className='references'>
                <div className='references__headline'>
                    <h2>Referenzen</h2>
                </div>
                <blockquote className='reference'>
                    <p>
                        "Herr Gören hat mich bereits bei mehreren Rechtsangelegenheiten (Privat und Gewerblich) beraten. Ich war mehr als zufrieden, da er alle Aspekte gründlich beleuchtet und mir verständlich erklärt hat. Von Herrn Gören würde ich mich daher immer wieder vertreten lassen."
                    </p>
                    <div className='reference__author'>
                            <span className='reference__author-name'><mark>Timo Krafft</mark></span>
                            <span className='reference__author-position'><mark>Geschäftsführer</mark></span>
                            <span className='reference__author-company'><mark>Manfred Krafft GmbH</mark></span>
                    </div>
                </blockquote>
            </div>
        </section>
    );
}

export default References;